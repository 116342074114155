import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    Renderer2,
    signal,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { Table } from "../../../s25-table/Table";
import { S25TableComponent } from "../../../s25-table/s25.table.component";
import { S25PricingTaxComponent } from "../s25-pricing-orgs/s25.pricing.tax.component";
import { PricingService } from "../../../../services/pricing.service";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { LineItemI, TotalsModel, UpdateData } from "../../../../pojo/Pricing";
import { PaymentSummaryData } from "../../s25-payments/s25.payments.component";
import { IPaymentDetails } from "../../s25-payments/payment.service";
import { Bind } from "../../../../decorators/bind.decorator";
import { S25Util } from "../../../../util/s25-util";
import { S25PricingItemComponent } from "../s25-pricing-orgs/s25.pricing.item.component";

@TypeManagerDecorator("s25-ng-pricing-totals")
@Component({
    selector: "s25-ng-pricing-totals",
    template: `@if (init()) {
        <div [class.expandable-rows]="summaryView">
            <s25-ng-table [caption]="'Pricing Totals'" [dataSource]="tableConfig"></s25-ng-table>
        </div>
    }`,
    styles: `
        :host ::ng-deep td {
            vertical-align: top !important;
        }

        :host ::ng-deep th {
            width: 10% !important;
        }

        :host ::ng-deep th[col="charge_to"] {
            width: 5em !important;
        }

        :host ::ng-deep .s25-ng-table--table {
            max-width: unset;
        }

        :host ::ng-deep .s25ngTable {
            max-width: unset;
            margin-right: 1em;
        }

        :host ::ng-deep .s25ngTable table {
            max-width: unset;
        }

        :host ::ng-deep .s25-ng-table--table tbody tr:nth-last-child(2) {
            border-bottom: 2px solid #555;
        }

        :host ::ng-deep .payment-breakdown-table .s25-ng-table--table tbody tr:nth-last-child(2) {
            border-bottom: 1px solid #e5e5e5;
        }

        :host ::ng-deep :host ::ng-deep .s25-ng-table--table tbody tr:last-child > td {
            padding-top: 1em !important;
        }

        :host ::ng-deep .rose-object-table-header {
            margin-bottom: unset;
        }

        :host ::ng-deep .s25ngTable .s25-ng-table--table .payment-breakdown-table {
            box-shadow:
                inset 0px 11px 8px -10px #ccc,
                inset 0px -11px 8px -10px #ccc;
        }

        :host ::ng-deep .s25ngTable .s25-ng-table--table tbody tr.payment-breakdown-table > td {
            padding: 0 0 0.5em 0 !important;
        }

        :host ::ng-deep .payment-breakdown-table .s25-ng-table--table .tableHeader {
            visibility: collapse;
        }

        :host ::ng-deep .payment-breakdown-table .s25ngTable {
            margin-right: unset;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingTotalsComponent implements OnInit {
    @Input() modelBean: TotalsModel;
    @Input() summaryView: boolean;
    @Input() paymentData: { [key: number]: PaymentSummaryData };
    @Input() invoiceData: any;

    @ViewChild(S25TableComponent) totalsTable: S25TableComponent;

    init = signal<boolean>(false);
    tableConfig: Table.DataSource;
    addPaymentData: boolean;
    chargeToMap: { [key: number]: string };
    paymentDataRows: IPaymentDetails[] = [];
    columns: Table.Column[];

    constructor(
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef,
    ) {}

    async ngOnInit() {
        this.addPaymentData = this.summaryView || !!this.modelBean.evBillId;

        if (this.addPaymentData) {
            this.setChargeToMap();
            if (this.summaryView) {
                this.modelBean.rows = this.compileSummaryData();
            }
            this.compilePaymentData();
        }

        await this.initTableConfig();
        this.init.set(true);
    }

    initTableConfig(updateData?: UpdateData) {
        updateData && this.processUpdateData(updateData);
        this.columns = this.setColumns();

        const getData = async () => {
            const data: any = await this.compileTotalsData();

            return {
                rows: data.map(this.mapToRows),
            };
        };

        this.tableConfig = {
            type: "unpaginated",
            dataSource: getData,
            columns: this.columns,
        };

        if (updateData) {
            this.totalsTable.dataSource = this.tableConfig;
            return this.totalsTable.refresh();
        }
    }

    setColumns() {
        return [
            { id: "item", header: "Item" },
            { id: "list_price", header: "List Price" },
            { id: "adjustments", header: "Adjustments" },
            { id: "price", header: "Price" },
            { id: "taxes", header: "Taxes" },
            { id: "total", header: "Total" },
            ...(this.addPaymentData
                ? [
                      { id: "payments", header: "Payments" },
                      { id: "balance", header: "Outstanding Balance", width: 120 },
                  ]
                : []),
            { id: "charge_to", header: "Charge To" },
        ];
    }

    @Bind
    mapToRows(item: any): Table.Row {
        return {
            id: "Pricing Totals",
            name: "PricingTotals",
            cells: {
                item:
                    this.summaryView && item.isPayment
                        ? {
                              component: S25PricingItemComponent,
                              inputs: { modelBean: item, isExpandable: true },
                              outputs: {
                                  showData: (paymentData: Table.NewRowModel) => this.addPaymentDataRow(paymentData),
                              },
                          }
                        : {
                              text: item.isAdjustment
                                  ? "Adjustment:"
                                  : item.charge_to_name
                                    ? "Subtotals:"
                                    : item.isPayment
                                      ? "Payments"
                                      : "Grand Total",
                          },
                list_price: {
                    text: S25Util.isDefined(item.list_price)
                        ? PricingService.formatCurrency(item.list_price)
                        : item.grandPriceList
                          ? PricingService.formatCurrency(item.grandPriceList)
                          : item.adjustment_name ?? "",
                },
                adjustments: {
                    text: item.adjustment_amt
                        ? typeof item.adjustment_amt === "string"
                            ? item.adjustment_amt
                            : PricingService.formatCurrency(item.adjustment_amt)
                        : item.adjustment_percent
                          ? `${item.adjustment_percent * 100}%`
                          : item.grandAdjustmentAmt
                            ? PricingService.formatCurrency(item.grandAdjustmentAmt)
                            : "",
                },
                price: {
                    text: S25Util.isDefined(item.taxable_amt)
                        ? PricingService.formatCurrency(item.taxable_amt)
                        : item.grandTaxableAmt
                          ? PricingService.formatCurrency(item.grandTaxableAmt)
                          : "",
                },
                taxes: {
                    ...(S25Util.isDefined(item.total_tax)
                        ? {
                              text:
                                  typeof item.total_tax === "string"
                                      ? item.total_tax
                                      : PricingService.formatCurrency(item.total_tax),
                          }
                        : item.grandTaxes && {
                              component: S25PricingTaxComponent,
                              inputs: { taxData: item.grandTaxes.tax },
                          }),
                },
                total: {
                    text: S25Util.isDefined(item.total_charge)
                        ? PricingService.formatCurrency(item.total_charge)
                        : item.grandTotalCharge
                          ? PricingService.formatCurrency(item.grandTotalCharge)
                          : "",
                },
                ...(this.addPaymentData && {
                    payments: {
                        text: S25Util.isDefined(item.amountTotalCents)
                            ? PricingService.formatCurrency(item.amountTotalCents)
                            : item.grandTotalPayments
                              ? PricingService.formatCurrency(item.grandTotalPayments)
                              : "",
                    },
                    balance: {
                        text: S25Util.isDefined(item.outstandingBalance)
                            ? PricingService.formatCurrency(item.outstandingBalance)
                            : item.grandTotalBalance
                              ? PricingService.formatCurrency(item.grandTotalBalance)
                              : "",
                    },
                }),
                charge_to: {
                    text: item.charge_to_name ?? (this.chargeToMap && this.chargeToMap[item.organizationId]) ?? "",
                },
            },
        };
    }

    filterDollarString(value: string | number) {
        if (typeof value === "string") return Number(value.replace("$", ""));
        return value;
    }

    compileSummaryData() {
        let orgData: any = [];
        return (
            Object.values(this.invoiceData.subtotals)
                ?.map((subtotal: any) => {
                    orgData.push({ orgId: subtotal.chargeToId, outstandingBalance: subtotal.outstandingBalance });
                    return PricingService.getSummaryFooterRows(subtotal);
                })
                ?.map((data: any, i: number) => {
                    const rowData = data[3].row;
                    const orgsData = orgData[i];
                    if (i === 0) {
                        this.modelBean.grandTaxableAmt = this.filterDollarString(rowData[3]);
                        this.modelBean.grandAdjustmentAmt = this.filterDollarString(rowData[2]);
                        this.modelBean.grandPriceList = this.filterDollarString(rowData[1]);
                        this.modelBean.total_tax = this.filterDollarString(rowData[4]);
                        this.modelBean.grandTotalCharge = this.filterDollarString(rowData[5]);
                    } else {
                        this.modelBean.grandTaxableAmt += this.filterDollarString(rowData[3]);
                        this.modelBean.grandAdjustmentAmt += this.filterDollarString(rowData[2]);
                        this.modelBean.grandPriceList += this.filterDollarString(rowData[1]);
                        this.modelBean.total_tax += this.filterDollarString(rowData[4]);
                        this.modelBean.grandTotalCharge += this.filterDollarString(rowData[5]);
                    }

                    return {
                        charge_to_name: this.chargeToMap[orgsData?.orgId],
                        charge_to_id: orgsData?.orgId,
                        list_price: rowData[1],
                        adjustment_amt: rowData[2],
                        taxable_amt: rowData[3],
                        total_tax: rowData[4],
                        total_charge: rowData[5],
                        remainingBalance: orgsData?.outstandingBalance,
                    };
                }) ?? []
        );
    }

    setChargeToMap() {
        if (this.summaryView) {
            this.chargeToMap = this.invoiceData.orgs?.reduce((map: { [key: number]: string }, org: any) => {
                map[org.organization_id] = org.organization_name;
                return map;
            }, {});
        } else {
            this.chargeToMap = this.modelBean.rows.reduce((map: { [key: number]: string }, row: LineItemI) => {
                if (!map[row.charge_to_id]) {
                    map[row.charge_to_id] = row.charge_to_name;
                }
                return map;
            }, {});
        }
    }

    compilePaymentData(isUpdate?: boolean) {
        if (isUpdate) this.paymentDataRows = [];
        let allOrgsData = [];
        for (let orgId in this.paymentData) {
            if (this.chargeToMap[orgId]) {
                const orgData = this.paymentData[orgId].filter(
                    (payment) =>
                        !payment.isVoid &&
                        payment.paymentStatus !== "unpaid" &&
                        (!this.modelBean.evBillId || payment.invoiceId === this.modelBean.evBillId),
                );

                const totalPayments = orgData.reduce((sum, payment) => sum + payment.amountTotalCents, 0) / 100;
                const balance =
                    this.modelBean.rows.find((row) => row.charge_to_id === +orgId && !row.isAdjustment)
                        ?.remainingBalance ?? 0;

                allOrgsData.push({
                    amountTotalCents: totalPayments,
                    organizationId: +orgId,
                    outstandingBalance: balance,
                    isPayment: true,
                    itemName: "Payments",
                    payments: orgData,
                });
            }
        }

        if (allOrgsData.length) {
            this.paymentDataRows = [...this.paymentDataRows, ...allOrgsData] as IPaymentDetails[];
        } else {
            this.addPaymentData = false;
        }
    }

    processUpdateData(updateData: UpdateData) {
        this.setChargeToMap();

        const eventsTotal = updateData.subtotals.reduce((sum: number, row: any) => {
            return sum + row.eventsTotalCharge;
        }, 0);

        const lineItemRows = updateData.subtotals.map((row: any) => {
            return {
                adjustment_amt: row.profileAdjustments + row.requirementsAdjustments,
                charge_to_id: row.chargeToId,
                charge_to_name: this.chargeToMap[row.chargeToId],
                list_price: row.occurrenceListPrice + row.requirementsListPrice,
                taxable_amt: row.taxableAmount,
                total_tax: row.tax,
                total_charge: row.eventsTotalCharge,
            };
        });

        const adjustmentRows =
            updateData.adjustments?.map((row: any) => {
                return {
                    adjustment_amt: row.adjustmentAmt,
                    adjustment_percent: row.adjustmentPercent / 100,
                    adjustment_name: row.adjustmentName,
                    charge_to_id: row.chargeToId,
                    charge_to_name: this.chargeToMap[row.chargeToId],
                    total_charge: row.totalCharge,
                    isAdjustment: true,
                    evBillId: row.evBillId,
                };
            }) ?? [];

        const newRows = [...lineItemRows, ...adjustmentRows];
        const { occurrenceListPrice, requirementsListPrice, taxableAmount, grandTotal } = updateData.totals;

        Object.assign(this.modelBean, {
            rows: newRows,
            grandPriceList: occurrenceListPrice + requirementsListPrice,
            grandAdjustmentAmt: newRows.reduce((sum: number, row: any) => {
                const amount = row.adjustment_amt ?? eventsTotal * row.adjustment_percent;
                return sum + amount;
            }, 0),
            grandTaxableAmt: taxableAmount,
            grandTaxes: { tax: Object.values(updateData.taxData) },
            grandTotalCharge: grandTotal,
        });

        this.addPaymentData && this.compilePaymentData(true);
    }

    @Bind
    async compileTotalsData() {
        if (!this.modelBean.rows.length) return [];

        const { grandAdjustmentAmt, grandPriceList, grandTaxableAmt, grandTaxes, grandTotalCharge } = this.modelBean;

        let grandTotals: TotalsModel = {
            grandAdjustmentAmt,
            grandPriceList,
            grandTaxableAmt,
            total_tax: this.summaryView ? this.modelBean.total_tax : null,
            grandTaxes: this.summaryView ? null : grandTaxes,
            grandTotalCharge,
        };
        if (this.addPaymentData) {
            const grandTotalPayments = this.paymentDataRows.reduce((sum, row) => sum + row.amountTotalCents, 0);

            if (grandTotalPayments > 0) {
                const grandTotalBalance = this.paymentDataRows.reduce((sum, row) => sum + row.outstandingBalance, 0);
                grandTotals = { ...grandTotals, grandTotalPayments, grandTotalBalance };
            } else {
                this.addPaymentData = false;
            }
        }
        return [...this.modelBean.rows, ...(this.addPaymentData ? this.paymentDataRows : []), grandTotals];
    }

    addPaymentDataRow(paymentData: Table.NewRowModel) {
        const tableEl = paymentData.table;

        if (paymentData.action === "create") {
            const paymentRow = tableEl.insertRow(paymentData.rowIndex + 1);
            paymentRow.ariaLabel = "Payment Breakdown";
            paymentRow.className = "payment-breakdown-table";
            paymentRow.tabIndex = 0;
            paymentRow.role = "listitem";
            paymentData.row.style.borderBottom = "unset";

            const cell = this.renderer.createElement("td");
            cell.colSpan = this.columns.length;
            this.renderer.appendChild(paymentRow, cell);

            const componentRef = this.viewContainerRef.createComponent(S25TableComponent);
            this.renderer.appendChild(cell, componentRef.location.nativeElement);

            componentRef.instance.dataSource = {
                type: "unpaginated",
                dataSource: () =>
                    Promise.resolve({
                        rows: paymentData.data.payments.map((payment: IPaymentDetails & { invoiceName: string }) => ({
                            id: payment.paymentDetailId,
                            name: "Payment",
                            cells: {
                                item: { text: "" },
                                list_price: { text: "", width: 100 },
                                adjustments: { text: "", width: 100 },
                                price: { text: "", width: 100 },
                                taxes: { text: "", width: 100 },
                                total: { text: "", width: 100 },
                                payments: { text: PricingService.formatCurrency(payment.amountTotalCents / 100) },
                                balance: { text: "", width: 100 },
                                charge_to: { text: payment.invoiceName },
                            },
                        })),
                    }),
                columns: this.columns,
            };
            return componentRef.instance.ngOnInit();
        } else {
            tableEl.deleteRow(paymentData.rowIndex + 1);
            paymentData.row.style.borderBottom = "2px solid #e5e5e5";
        }
    }
}
